import React from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import IneChart from "../components/IneChart"
import Slide from "../components/Slide"
import NavBar from "../components/NavBar"
import Jumbo from "../components/Jumbo"
import Footer from "../components/Footer"

export function Head() {
  return (
    <>
      <title>Instalación de paneles solares para la agricultura - campo</title>
      <meta
        name="description"
        content="Agricultores a lo largo del país están descubriendo y disfrutando de las ventajas de contar con energía solar."
      />
      <meta
        name="keywords"
        content="Agrivoltaico, agrovoltaico, energia solar campo, paneles solares agricultura, agricultores, placas fovotoltaicas, agricultor"
      />
    </>
  )
}

class Agricultura extends React.Component {
  constructor(props) {
    super(props)
    this.click = this.click.bind(this)
  }

  click(e) {
    e.preventDefault()
    navigate("/miplanta?tipo=agricultura")
  }

  render() {
    const remote = getImage(this.props.remote)
    return (
      <>
        <NavBar {...this.props} />

        <Jumbo jumbo={this.props.jumbo}>
          <h1>Campo Solar</h1>
          <h2>Pon al sol a trabajar la Tierra</h2>
        </Jumbo>
        <div className="copyright">
          <div className="col-md-12 col-xs-12 text-center">
            <a href="#agricultores">
              <i className="fa-solid fa-angle-down fa-bounce"></i>
            </a>
          </div>
        </div>

        <section id="agricultura">
          <div className="container">
            <Slide id="agricultores" nextSlide="#ahorro">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="text-center">
                    <h2>
                      Por qué los <b>agricultores</b> están eligiendo energía
                      solar
                    </h2>
                    <h4>
                      Agricultores a lo largo del país están descubriendo y
                      disfrutando de las ventajas de contar con energía solar.
                      Hemos trabajado con un gran número de agricultores,
                      ayudándoles a adoptar y recibir los beneficios de usar
                      paneles fotovoltaicos.
                    </h4>
                  </div>
                </div>
              </div>

              <div className="row text-center">
                <div className="col-12 col-sm-4">
                  <div className="feature_content black">
                    <i className="fa-solid fa-dollar"></i>
                    <h5>Ahorro</h5>
                    <p>
                      Disminuye drásticamente tu cuenta eléctrica. Nuestros
                      sistemas son diseñados a la medida según tus necesidades
                      de consumo. Cada kilowatt-hora (kWh) producido por tu
                      planta solar será uno menos que le tendrás que pagar a la
                      compañía eléctrica. Esto se traduce en más recursos
                      disponibles para tu negocio.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="feature_content black">
                    <i className="fa-solid fa-shield"></i>
                    <h5>Protección</h5>
                    <p>
                      Protección de los crecientes precios de la energía. En vez
                      de mantenerse vulnerable al siempre creciente precio de la
                      electricidad, la energía solar provee a agricultores de
                      una solución costo-eficiente y confiable.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="feature_content black">
                    <i className="fa-solid fa-line-chart"></i>
                    <h5>Inversión</h5>
                    <p>
                      Aumenta el valor de tu propiedad. Los paneles solares son
                      una gran inversión al valor de largo plazo y viabilidad de
                      tu tierra. Es una mirada a futuro, para tu familia y tu
                      propiedad.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row text-center">
                <div className="col-12 col-sm-4">
                  <div className="feature_content black">
                    <i className="fa-solid fa-tachometer"></i>
                    <h5>Autonomía</h5>
                    <p>
                      Obtén independencia eléctrica. La energía solar es una
                      fuente energía eléctrica gratuita; mientras el sol brille,
                      los paneles producirán electricidad.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="feature_content black">
                    <i className="fa-solid fa-bolt"></i>
                    <h5>Integración</h5>
                    <p>
                      Nuestros paneles no afectan el sistema eléctrico instalado
                      actualmente, por lo tanto, en la mayoría de los casos, no
                      es necesario hacer extensiones ni modificaciones de alto
                      costo. La instalación de nuestro sistema se integra
                      armoniosamente a la ya existente.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="feature_content black">
                    <i className="fa-solid fa-wrench"></i>
                    <h5>Mantenimiento</h5>
                    <p>
                      Porque no requieren combustible y no tienen componentes
                      móviles, los paneles solares son mucho más convenientes
                      para operar y mantener que generadores diesel o a
                      gasolina.
                    </p>
                  </div>
                </div>
              </div>
            </Slide>

            <Slide id="ahorro" nextSlide="#usos">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="text-center">
                    <h2>
                      Comienza a <b>Ahorrar</b>
                    </h2>
                    <h4>
                      Para entender los grandes beneficios de la energía solar,
                      solamente se requiere tener la visión y la voluntad de
                      hacer inversiones de largo plazo que mejoren la operación,
                      la producción y la rentabilidad de tu predio.
                    </h4>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6 col-12 my-auto">
                  <p>
                    En los últimos 40 años, el precio de la electricidad se ha
                    mantenido en un constante aumento presentando además
                    importantes oscilaciones y disminuyendo considerablemente el
                    márgen de utilidad de los agricultores. Por otra parte, la
                    volatilidad de los precios del combustible hace muy díficil
                    proyectar los gastos a mediano y corto plazo. La energía
                    solar, como fuente de independencia energética, ofrece
                    estabilidad y confianza en la capacidad de resolver los
                    requerimientos de energía eléctrica.
                  </p>
                  <div className="mb-0 text-center">
                    <a
                      href="\#"
                      className="btn btn-primary"
                      onClick={this.click}
                    >
                      Empieza a Ahorrar
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                  <IneChart className="my-auto" />
                </div>
              </div>
            </Slide>

            <Slide id="usos" nextSlide="visita">
              <div className="text-center">
                <h2>
                  <b>Usos</b> de la energía solar en el mundo agrícola
                </h2>
                <h4>
                  Día tras día se descubren nuevas y mejores aplicaciones para
                  la energía solar en la agricultura
                </h4>
              </div>

              <div className="row">
                <div className="col-sm-6 col-12">
                  <GatsbyImage
                    image={remote}
                    alt="Paneles solares en agricultura"
                  />
                </div>

                <div className="col-sm-6 col-12 vcenter">
                  <p>
                    Los plantas fotovoltaivas son una opción altamente confiable
                    y de bajo mantenimiento. Actualmente, el principal uso que
                    se le da es para:
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <ul className="bullet-list bullet-list-wide">
                    <li>
                      <b>Bombeo de agua solar</b>: ya sea directamente o
                      utilizando estanques acumuladores, para sistemas de
                      irrigación y para alimentar ganado
                    </li>
                    <li>
                      <b>Aeración del agua</b>: provee de oxígeno necesario a
                      pozos y estanques de ganado
                    </li>
                    <li>
                      <b>Electricidad</b>: usada para entregar luz, calefacción,
                      accionar equipamiento eléctrico y refrigeración, entre
                      otros
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul className="bullet-list bullet-list-wide">
                    <li>
                      <b>Seguridad</b>: como medida de protección de activos,
                      muchos agricultores controlan sus medidas de seguridad con
                      energía solar, como iluminación, cercos eléctricos y
                      apertura de puertas.
                    </li>
                    <li>
                      <b>Ventilación</b>: sistemas de ventilación para mantener
                      ambientes limpios y frescos.
                    </li>
                    <li>
                      <b>Abastecimiento remoto</b>: En algunas zonas, la
                      distancia a la cual la planta fotovolcaica off grid, se
                      vuelve económicamente viable a cambio de tendido eléctrico
                      y transformadores o generadores a combustibles fósiles, es
                      sorprendentemente corta
                    </li>
                  </ul>
                </div>
              </div>
            </Slide>

            <Slide id="visita" className="small" isLastSlide={true}>
              <div className="col-md-12 col-sm-12 col-12">
                <div className="text-center">
                  <h2>
                    Pon al sol a trabajar la <b>Tierra</b>
                  </h2>
                  <h4>Diseñando tu propia planta solar.</h4>
                  <a href="\#" className="btn btn-primary" onClick={this.click}>
                    Ingresar
                  </a>
                </div>
              </div>
            </Slide>
          </div>
        </section>

        <Footer />
      </>
    )
  }
}

function Page(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          jumbo: file(relativePath: { eq: "agricultura.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          remote: file(relativePath: { eq: "remote.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={data => (
        <Agricultura jumbo={data.jumbo} remote={data.remote} {...props} />
      )}
    />
  )
}
export default Page
