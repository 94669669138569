import React, { Component } from "react"

import "../../../node_modules/charts.css/dist/charts.min.css"
import "./inechart.scss"

var electricidadData = {
  labels: [
    "Ene 2014",
    "Feb 2014",
    "Mar 2014",
    "Abr 2014",
    "May 2014",
    "Jun 2014",
    "Jul 2014",
    "Ago 2014",
    "Sep 2014",
    "Oct 2014",
    "Nov 2014",
    "Dic 2014",
    "Ene 2015",
    "Feb 2015",
    "Mar 2015",
    "Abr 2015",
    "May 2015",
    "Jun 2015",
    "Jul 2015",
    "Ago 2015",
    "Sep 2015",
    "Oct 2015",
    "Nov 2015",
    "Dic 2015",
    "Ene 2016",
    "Feb 2016",
    "Mar 2016",
    "Abr 2016",
    "May 2016",
  ],

  datasets: [
    {
      fill: "start",
      backgroundColor: "rgba(0,50,171,0.5)",
      data: [
        100.0, 100.16, 100.09, 100.35, 100.53, 100.55, 100.66, 100.54, 100.7,
        100.47, 104.93, 106.18, 105.9, 116.13, 115.96, 115.94, 116.15, 119.9,
        121.94, 121.79, 121.72, 121.95, 121.06, 120.85, 122.21, 129.52, 131.05,
        132.1, 132.02,
      ],
      label: "precio de la electricidad",
    },
  ],
}

export default class IneChart extends Component {
  render() {
    const max = Math.max(...electricidadData.datasets[0].data)
    const min = Math.min(...electricidadData.datasets[0].data)
    return (
      <>
        <table
          className="charts-css area show-heading show-labels"
          id="ine-chart"
        >
          <caption className="text-center">
            Indices de Precios de la Electricidad en Chile
          </caption>
          <tbody>
            {electricidadData.labels.map((label, index) => {
              let value = electricidadData.datasets[0].data[index]

              let start = 0
              if (index > 0) {
                start =
                  (electricidadData.datasets[0].data[index - 1] - min) /
                  (max - min)
              }

              const size = (value - min) / (max - min)
              value = Math.round(value)
              return (
                <tr key={index}>
                  {index % 6 == 0 ? <th scope="row">{label}</th> : null}
                  <td style={{ "--start": start, "--size": size }}>
                    {/*index % 3 == 0 ? (
                    <span className="data">{value}</span>
                  ) : null*/}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <p className="ine-caption">
          Fuente: Instituto Nacional de Estadísticas de Chile, 2016
        </p>
      </>
    )
  }
}
