import React, { Component } from "react"
import PropTypes from "prop-types"

export default class Slide extends Component {
  render() {
    return (
      <div
        id={this.props.id}
        className={"content-slide section row " + this.props.className}
      >
        {this.props.children}
        {
          // if not last slide add arrow button
          this.props.isLastSlide ? (
            ""
          ) : (
            <div className="col-md-12 col-xs-12 text-center">
              <a href={this.props.nextSlide}>
                <i className="fa-solid fa-angle-down fa-bounce"></i>
              </a>
              <hr />
            </div>
          )
        }
      </div>
    )
  }
}

Slide.propTypes = {
  id: PropTypes.string,
  nextSlide: PropTypes.string,
  isLastSlide: PropTypes.bool,
  className: PropTypes.string,
}

Slide.defaultProps = {
  id: "",
  nextSlide: "",
  isLastSlide: false,
  className: "",
}
